import {call,put,takeLatest,all, takeEvery} from "redux-saga/effects";
import api from "../../api";
import {fetchDataSuccess,fetchDataError,fetchUserInfo,
  fetchUserInfoSuccess,fetchUserInfoFailure,setExampleSuccess,
  setExampleFailure,setDataSuccess,setDataFailure,resetIsError,
  setBackEnd_Success,setBackEnd_Failure,setByKeyFixed,
  setValueByKeyAndId,setByKey,setOrderByDbtype,setFinishTasksToday} from '../actions/userInfoActions';
import {FETCH_DATA_REQUEST,FETCH_USER_INFO,SET_DATA_EXAMPLE,SET_DATA_REQUEST,SET_BACKEND,SET_TASKS_FINISH_TODAY} from "../types/userInfoTypes"
import {gotUserData} from '../actions/authActions';
import localStorageUtil from "../../localStorageUtil";





 function* fetchUserInfo_Saga(){
  try{
    const response=yield call(api.getUserInfo);
    const userInfo=yield response.json();
    yield put(fetchUserInfoSuccess(userInfo))
    yield put(gotUserData());
  }
  catch(err){
    yield put(fetchUserInfoFailure(`get user info - Internet Error`))
    
  }
}

 function* fetchAllData_Saga({dbtype}){
    try{
        const response=yield call(api.getAll,dbtype);
        switch (response.status){
            case 200:
                const data=yield response.json();
                yield put(fetchDataSuccess(dbtype,data));
                break;
            case 500:
                yield put(fetchDataError(`${dbtype} - Server Error`));
                break;
            default:
                yield put(fetchDataError('Req Status !200 && !500 '))
                break;
        }
    }
    catch(err){
        put(fetchDataError(`${dbtype} - Internet Error`));
    }

}

function* setExampleData_Saga({dbtype,value}){
  try{
    const response=yield call(api.setExampleData,dbtype,value);
    if(response.ok){
      yield put(fetchUserInfo());
    }
    else{
      yield put(setExampleFailure());
    }
  }
  catch(err){
    yield put(setExampleFailure());
  }
}
  
function* setData_Saga({dbtype,method,data}){
  try{
    const response=yield call(api.setData,dbtype,method,data);
    if(response && response.ok){
      if(dbtype==='user'&&method==='POST'){
        yield put(setDataSuccess());
        return;
      }
      const checkData=yield response.text();
      if(checkData!=="OK"){

        const retunredData=yield response.json();
        yield put(setDataSuccess(dbtype,method,retunredData));
      }
      else{
        yield put(setDataSuccess());
      }
      
    }
    else{
      yield put(setDataFailure());
      
    }
  }
  catch(err){
    console.log(err);
    yield put(setDataFailure());
    
  }

}

function* watchFetchUserInfo(){
  yield takeLatest(FETCH_USER_INFO,fetchUserInfo_Saga)
}

function* setBackend_Saga({dbtype,path,method,data,isResponse,key_andIdObj}){
  try{
    const response=yield call(api[method],dbtype,path,data);
    if(response && response.ok){
      !isResponse ? yield put(setBackEnd_Success()) : yield put(setValueByKeyAndId(key_andIdObj.key,key_andIdObj._id,key_andIdObj.value)) ;
     
        if(path=='update-order'){
          yield put(setOrderByDbtype(dbtype,data));
        }
      
      
    }
    else{
      yield put(setBackEnd_Failure());
      
    }
  }
  catch(err){
    console.log(err);
    yield put(setBackEnd_Failure());
    
  }
}

function* setFinishTasksToday_Saga(){
  try{
    const localData=localStorageUtil.getByDbType('task');
    yield put(setByKeyFixed('finishTasksToday',localData));
  }
  catch(err){
    console.log(err);
  }
}





function* watchFetchData(){
  yield takeLatest(FETCH_DATA_REQUEST,fetchAllData_Saga);
}

function* watchSetExampleData(){
  yield takeEvery(SET_DATA_EXAMPLE,setExampleData_Saga)
}
function* watchSetBackend(){
  yield takeEvery(SET_BACKEND,setBackend_Saga)
}
function* watchFinishTasksToday(){
  yield takeEvery(SET_TASKS_FINISH_TODAY,setFinishTasksToday_Saga)
}


function* watchSetData(){
  yield takeEvery(SET_DATA_REQUEST,setData_Saga)
}

export default function* userInfoSaga(){
  yield all([
    watchFetchUserInfo(),
    watchFetchData(),
    watchSetExampleData(),
    watchSetData(),
    watchSetBackend(),
    watchFinishTasksToday(),
  ])

}