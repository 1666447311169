import React from 'react';
import { useEffect,useState } from 'react';
import {SortDelTable,DataCarousel} from '../';

function extractSpecificKeys(objectsArray, keys) {
    return objectsArray.map(object => {
      const newObj = {};
      keys.forEach(key => {
        if (object.hasOwnProperty(key)) {
          newObj[key] = object[key];
        }
      });
      return newObj;
    });
  }

const Incomes = ({incomes}) => {
    const headCells = [
        {
          key:'name',
          label: 'Name',
        },
        {
          key: 'averagePaycheck',
          label: 'Average Paycheck',
        },
        {
          key: 'coin',
          label: 'Coin',
        },
      ];
      
      const rows=extractSpecificKeys(incomes,['name','averagePaycheck','coin']);
      const [tableData,setTableData]=useState({headCells,dataList:rows,header:'Incomes'});
      useEffect(()=>{
        const rows=extractSpecificKeys(incomes,['name','averagePaycheck','coin']);
        setTableData({...tableData,dataList:rows})
      },[incomes])
    // const headCells = [
    //     {
    //       id: 'name',
    //       numeric: false,
    //       disablePadding: true,
    //       label: 'Name',
    //     },
    //     {
    //       id: 'averagePaycheck',
    //       numeric: true,
    //       disablePadding: false,
    //       label: 'Average Paycheck',
    //     },
    //     {
    //       id: 'coin',
    //       numeric: false,
    //       disablePadding: false,
    //       label: 'Coin',
    //     },
    //   ];
    //   const rows=extractSpecificKeys(incomes,['_id','name','averagePaycheck','coin']);
    //   const objectKeys=['averagePaycheck','coin'];
    //   const [tableData,setTableData]=useState({headCells,objectKeys,rows});
    //   useEffect(()=>{
    //     setTableData({...tableData,rows:incomes})
    //   },[incomes])
    return (
        <div>
            <DataCarousel headCells={tableData.headCells} dataList={tableData.dataList} header={tableData.header}/>
            {/* <SortDelTable positive headCells={tableData.headCells} dataToRows={tableData.rows} objetKeys={tableData.objectKeys} header='Incomes'/> */}
        </div>
    );
};

export default Incomes;