import React from 'react';
import {Carousel} from 'react-responsive-carousel';
const DataCarousel = ({headCells,dataList,header,rowPerPage=10}) => {
  
        const pagesNumber = Math.floor(dataList.length / rowPerPage) + (dataList.length % rowPerPage);
    return (
        <div className='data_carousel-container'>
            <h1>{header}</h1>
            <table className='theme'>
                    <thead>
                        <tr>
                            {headCells.map(headCell=><th>{headCell.label}</th>)}
                            <th>Edit</th>
                        </tr>
                    </thead>
                            {(new Array(pagesNumber).fill(null)).map((_,index)=>{
                                const startIndex=(index*rowPerPage);
                                const filledRows=startIndex+rowPerPage-dataList.length >0 ? startIndex+rowPerPage-dataList.length : 0;
                                return (
                                        <tbody>
                                            {(new Array(rowPerPage).fill(null)).map((_,index)=>
                                                filledRows+index-rowPerPage>=0 ?
                                                <tr>
                                                {headCells.map(_=><td></td>)}
                                                    <td></td>
                                                </tr>
                                                :
                                                <tr>
                                                    {headCells.map(headCell=><td>{dataList[startIndex+index][headCell.key]}</td>)}
                                                    <td>
                                                        <span>Add Edit Icons</span>
                                                    </td>
                                                </tr>
                                            )}
                                         </tbody>
                                    )
                                }
                            )}
                    
                </table>
        </div>
    );
};

export default DataCarousel;