import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { useEffect } from 'react';
import Incomes from './Incomes';
import BankInfo from './BankInfo';
import Expenses from './Expenses';
import FinancialConduct from './FinancialConduct';
import PageSlider from '../PageSlider';
import {BarsChart} from '../';
import store from '../../store';


const bankPages=(banks,incomes,expenses,cards,currenciesRatio)=>{
    let bankPages=[];
    for(const i in banks){
      bankPages.push({
        heading:'Incomes & Expenses & Investments'
      });
      const filteredCardsIds=Array.isArray(cards) && cards.length>0 ? cards.filter(val=>val.bankId==banks[i]._id).map(card=>card['_id']) : [];

      const bankIncomes=Array.isArray(incomes) && incomes.length>0 ? incomes.filter(val=>val.bankId==banks[i]._id): [];
      const bankExpenses=Array.isArray(expenses) && expenses.length>0 ? expenses.filter(val=>filteredCardsIds.includes(val.cardId)) : [];
      const sumInExByHomeCoinData=store.getIncomeExpensesReport_byCurrency('shekel',bankIncomes,bankExpenses,currenciesRatio);
      // change to sum of last 12 month + projects and shit
      const averageLastYearlyIncome=12*sumInExByHomeCoinData.income/12;
      let rechart12NextMonths=store.rechartGetNext12Months((new Date()).getMonth());
      const rechartFinanceData=rechart12NextMonths.map(rechartDataObj=>({...rechartDataObj,Incomes:sumInExByHomeCoinData.income,Expenses:sumInExByHomeCoinData.expense,Investments:sumInExByHomeCoinData.investment}))
      const barsColors={Incomes:'#82ca9d',Expenses:'rgba(255, 165, 0)',Investments:'rgb(0, 128, 255)'};
      bankPages[i]['name']=banks[i].name;
      bankPages[i]['component']=()=>
          <div>
            <FinancialConduct/>
            <BankInfo currenciesRatio={currenciesRatio} bank={banks[i]} sumIncomes={bankIncomes} sumExpenses={bankExpenses}/>
            <div style={{width:'100%',height:'500px'}} className='finance chart-container'>
              <BarsChart data={rechartFinanceData} chosenColors={barsColors}/>
            </div>
              <div className=" flex-even   ">
                <Incomes incomes={bankIncomes}/>
                <Expenses expenses={bankExpenses}/>
              </div>
          </div>
      bankPages[i]['subHeader']=`Average Income Last 12 Month :${averageLastYearlyIncome.toFixed(0)}₪  (Needs Update)`;
    }
    return bankPages;
}
const Finance = ({ cards, expenses , month , banks, incomes,paycheck,currenciesRatio }) => {
  // const bankNameById=bankId=>banks.find(bank=>bank._id==bankId)?.name ?? 'Bank Not Found';
  const classes='container-boxShadow finance-slider';
  useEffect(()=>{},[banks,cards,expenses]);
    return (
      <div className='finance-container'>
      
        { Array.isArray(banks) && banks.length>0 ? 
            <PageSlider classes={classes} pages={bankPages(banks,incomes,expenses,cards,currenciesRatio)}/>
            
          :
          <div>
              <h1>Add Bank Account</h1>
          </div>
          }
      
    </div>
    );
  };
  
  
const mapStateToProps = (state) => {
    return {
      banks:state.userInfo.bank,
      cards: state.userInfo.card,
      expenses:state.userInfo.expense,
      incomes:state.userInfo.income,
      paychecks:state.userInfo.paycheck,
      currenciesRatio:state.userInfo.currenciesRatio,
      month:(moment().month()+1).toString().padStart(2,'0')
    };
  };
export default connect(mapStateToProps)(Finance);