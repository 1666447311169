import React from 'react';
import { useEffect,useState } from 'react';
import {SortDelTable,DataCarousel} from '../';
function extractSpecificKeys(objectsArray, keys) {
    return objectsArray.map(object => {
      const newObj = {};
      keys.forEach(key => {
        if (object.hasOwnProperty(key)) {
          newObj[key] = object[key];
        }
      });
      return newObj;
    });
  }
const Expenses = ({expenses}) => {

  
    const headCells = [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'cost',
            label: 'Cost',
        },
        {
          key: 'billingMonthDay',
          label: 'Billing date',
        },
        {
          key: 'coin',
          label: 'Coin',
        },
        {
          key: 'type',
            label: 'Type',
          },
      ];
      const rows=extractSpecificKeys(expenses,['name','cost','billingMonthDay','coin','type']);
      const [tableData,setTableData]=useState({headCells,dataList:rows,header:'Expenses'});
      useEffect(()=>{
        const rows=extractSpecificKeys(expenses,['name','cost','billingMonthDay','coin','type']);
        setTableData({...tableData,dataList:rows});
      },[expenses])
    return (
        <div className='expenses-container'>
            <DataCarousel headCells={tableData.headCells} dataList={tableData.dataList} header={tableData.header} rowPerPage={tableData.dataList.length}/>
        </div>
    );
};

export default Expenses;